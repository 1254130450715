<template>
  <ModalFull
    :is-visible="isVisible"
    :reset-container="true"
    container-class="w-full max-w-3xl"
  >
    <div class="notice-block">
      <div class="flex flex-col justify-between py-6 px-7 text-left text-sm ">
        <div class="flex flex-col">
          <h4 class="text-base font-semibold text-theme-dark mb-3">
            Inventory Notice
          </h4>

          <p class="text-theme-body font-light">
            There are some products in your order that are no longer available
            and have to be removed before you can checkout.
          </p>

          <div class="space-y-5 my-4">
            <p
              class="text-sm text-theme-body inline-flex items-center justify-center"
            >
              <Icon name="info-filled" class="w-3.5 h-3.5 mr-1.5 -mt-0.5" />

              WHY?
            </p>

            <p class="text-theme-body font-light">
              Unfortunately, some of the products that you had in your cart are
              in high demand and were ordered by a customer before you.
            </p>

            <!-- Notification Alert Checkbox -->
            <div
              class="flex items-center justify-start whitespace-nowrap text-ellipsis overflow-hidden w-full select-none"
            >
              <span>
                <input
                  id="notify-inv"
                  name="notify-inv"
                  value="notify"
                  @change="notifyCheck = true"
                  type="checkbox"
                  class="h-4 w-4 border-2 mb-0.5
              border-brand-gray border-opacity-50 rounded text-brand-secondary
              focus:ring-transparent"
                />
                <label
                  for="notify-inv"
                  class="text-sm font-light text-theme-body whitespace-nowrap"
                >
                  Alert me when products are available.
                </label>
              </span>
            </div>
          </div>
        </div>
        <div class="flex items-center justify-center mt-5">
          <router-link to="/">
            <button class="btn" @click="$emit('close'), notify()">
              Okay
            </button>
          </router-link>
        </div>
      </div>

      <div class="flex flex-col">
        <div
          class="w-full flex items-center justify-end px-6 py-3.5 leading-none border-b border-gray-200 space-x-5"
        >
          <span class="text-theme-body text-xxs">Quantity Removed</span>
        </div>

        <div class="items-list">
          <div
            class="flex items-center justify-between space-x-4 px-6 py-2"
            v-for="(item, idx) in items"
            :key="`${idx}-${item.name}`"
          >
            <div class="flex items-center space-x-3 flex-shrink-0">
              <img
                :src="item.image"
                alt="Image"
                class="w-8 h-8 mr-1 object-contain object-center"
              />

              <div class="flex-grow text-left">
                <p
                  class="text-xs leading-none text-brand-secondary font-semibold mb-0.5"
                >
                  {{ item.name }}
                </p>

                <span
                  class="text-xxs leading-none text-brand-gray font-normal"
                  >{{ item.brand }}</span
                >
              </div>
            </div>

            <span class="text-md leading-none text-brand-secondary font-medium">
              {{ item.qty }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </ModalFull>
</template>
<script>
import { inject } from "vue";
export default {
  name: "InventoryNoticeModal",
  props: {
    isVisible: Boolean,
    items: Array,
  },
  emits: ["close", "confirm"],
  data() {
    return {
      notifyCheck: false,
      axios: inject("axios"),
    };
  },
  methods: {
    notify() {
      let ids = this.items.map((i) => i.id);
      console.log(ids);
      if (this.notifyCheck) {
        this.axios.authApi
          .post("bulkNotify", { variant_ids: ids })
          .then((res) => {
            console.log(res);
          })
          .catch((err) => console.log(err));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.notice-block {
  @apply bg-white grid sm:grid-cols-2 w-full divide-x divide-gray-100 rounded -mt-16;

  @media (max-width: 640px) {
    max-height: 90vh;
    @include CssScrollbar();
    overflow-y: auto;
  }

  .btn {
    @apply inline-block w-36 text-center text-sm  leading-none px-2 py-3 rounded focus:outline-none bg-theme-secondary hover:bg-black text-white;
  }

  .items-list {
    @apply overflow-y-auto space-y-0 py-2;
    @media (min-width: 700px) {
      max-height: 400px;
      @include CssScrollbar();
    }
  }
}
</style>
