import { inject, reactive } from 'vue';

export default function Address(){
    const axios = inject('axios');
    const addresses = reactive({
        loading: false,
        data: [],
        error: ''
    });

    const getAddresses = () => {
        addresses.loading = true;
        axios.authApi.get('/licenseAddresses').then(resp => {
            if(resp.status){
                addresses.data = resp.data.data;
            } else {
                addresses.error = resp.error;
            }
            addresses.loading = false
        }).catch(error => {
            addresses.loading = false
            console.log(error);
        });
    }

    return {
        addresses,
        getAddresses
    }
}