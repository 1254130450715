<template>
  <div>
    <div class="info-title">
      <div class="title">
        <Icon name="document-filled"/>
        <span>Tax Summary</span>
      </div>
    </div>
    <div class="tax-summary">
      <div class="tax-item" v-for="(tax, index) in data" :key="`${tax.label}-${index}`">
        <p>{{ tax.label }}</p>
        <span>${{ commaFormat((tax.total || 0).toFixed(2)) }}</span>
      </div>
      <div class="tax-item highlight">
        <p>Total Taxes</p>
        <span>${{ commaFormat((total || 0).toFixed(2)) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import Mymixin from "@/modules/mixins";
export default {
  name: "TaxSummary",
  mixins:[Mymixin],
  props:{
    data: Object,
    total: Number
  }
}
</script>

<style lang="scss" scoped>
.tax-summary{
  @apply flex flex-col space-y-3;
  .tax-item{
    @apply w-full flex items-center justify-between text-sm font-light text-theme-body px-4;

    &.highlight{
      @apply font-semibold text-black border-t border-theme-border border-opacity-50 py-3;
    }
  }
}
.info-title{
  @apply flex items-center justify-between px-4 py-3;
  .title{
    @apply flex items-center text-md text-theme-secondary font-light space-x-2;
    svg{
      @apply w-5 h-5;
    }
  }
  .price{
    @apply text-sm text-black font-semibold;
  }
}
</style>