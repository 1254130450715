<template>
  <ModalFull
    :is-visible="isVisible"
    :reset-container="true"
    container-class="w-full max-w-3xl"
  >
    <div class="notice-block">
      <div class="flex flex-col py-6 px-7 text-left text-sm ">
        <h4 class="text-base font-semibold text-theme-dark mb-3">Notice</h4>

        <p class="text-theme-body font-light">
          There are some products in your order that are no longer available for
          purchase. These products will be removed before you can check out.
        </p>

        <div class="flex min-w-0 items-center justify-between space-x-3 my-7">
          <div
            class="flex items-center justify-center rounded-full w-10 h-10 text-brand-indigo bg-purple-100 flex-shrink-0 order-bg"
          >
            <Icon name="shop" class="w-6 h-6" />
          </div>

          <div class="flex-1 flex flex-col min-w-0">
            <span class="text-brand-secondary text-sm font-normal truncate"
              >Store</span
            >

            <span class=" text-brand-body text-sm truncate">Address YYYY</span>
          </div>
        </div>

        <div class="space-y-5 my-4">
          <p
            class="text-sm text-theme-body inline-flex items-center justify-center"
          >
            <Icon name="info-filled" class="w-3.5 h-3.5 mr-1.5 -mt-0.5" />

            WHY?
          </p>

          <p class="text-theme-body font-light">
            Products are only reserved for you when you complete the checkout
            and unfortunately, sometimes things can sell out before you complete
            the checkout
          </p>
        </div>

        <div class="grid grid-cols-2 gap-8 mt-5">
          <button class="btn" @click="$emit('close')">Cancel</button>

          <button class="btn remove" @click="$emit('confirm')">
            Remove Items
          </button>
        </div>
      </div>

      <div class="flex flex-col">
        <div
          class="w-full flex items-center justify-start px-6 py-3.5 leading-none border-b border-gray-200 space-x-5"
        >
          <p class="text-sm text-theme-body">
            <span class="text-theme-secondary font-medium">12</span> Products
          </p>

          <span class="h-0.5 bg-gray-100 w-24 hidden sm:inline-block"></span>

          <span class="text-theme-body text-xxs">will be removed</span>
        </div>

        <div class="items-list">
          <div
            class="flex items-center justify-between space-x-4 px-6 py-2"
            v-for="(item, idx) in items"
            :key="`${idx}-${item.name}`"
          >
            <div class="flex items-center space-x-3 flex-shrink-0">
              <img
                :src="item.image"
                alt="Image"
                class="w-8 h-8 mr-1 object-contain object-center"
              />

              <div class="flex-grow text-left">
                <p
                  class="text-xs leading-none text-brand-secondary font-semibold mb-0.5"
                >
                  {{ item.name }}
                </p>

                <span
                  class="text-xxs leading-none text-brand-gray font-normal"
                  >{{ item.brand }}</span
                >
              </div>
            </div>

            <span class="text-md leading-none text-brand-secondary font-medium">
              {{ item.qty }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </ModalFull>
</template>
<script>
export default {
  name: "NoticeModal",
  props: {
    isVisible: Boolean,
    items: Array,
  },
  emits: ["close", "confirm"],
};
</script>

<style lang="scss" scoped>
.notice-block {
  @apply bg-white grid sm:grid-cols-2 w-full divide-x divide-gray-100 rounded -mt-16;

  @media (max-width: 640px) {
    max-height: 90vh;
    @include CssScrollbar();
    overflow-y: auto;
  }

  .btn {
    @apply inline-block w-36 text-center text-sm  leading-none px-2 py-3 rounded focus:outline-none hover:text-gray-400;
    color: #bbbbbb;
    &.remove {
      color: #ab304d;
      background: rgba(251, 69, 123, 0.1);
    }
  }

  .items-list {
    @apply overflow-y-auto space-y-0 py-2;
    @media (min-width: 700px) {
      max-height: 400px;
      @include CssScrollbar();
    }
  }
}
</style>
