<template>
  <ModalFull
    :is-visible="isVisible"
    :reset-container="true"
    container-class="w-full max-w-md"
  >
    <div class="notice-block">
      <div class="flex flex-col justify-between py-6 px-7 text-left text-sm ">
        <div class="flex flex-col">
          <h4
            class="flex items-center gap-1 text-base font-medium text-theme-dark mb-3 leading-none"
          >
            <Icon name="credit-card" class="w-5 h-5 -mt-0.5 text-red-700" />
            <span>{{ title }}</span>
          </h4>

          <p class="text-theme-body font-medium">{{ description }}</p>

          <!-- Payment Decline Contents -->
          <div class="space-y-5 my-4" v-if="!$slots.default">
            <p
              class="text-sm text-theme-body inline-flex items-center justify-center"
            >
              <Icon name="info-filled" class="w-3.5 h-3.5 mr-1.5 -mt-0.5" />

              WHY?
            </p>

            <p class="text-theme-body font-light" v-if="type == 'non-avs'">
              There are numerous reasons your credit card issuer could have
              declined the payment. Unfortunately, we don't have any more
              information.
            </p>

            <p class="text-theme-body font-light" v-if="type == 'non-avs'">
              Please try another payment method and contact your credit card
              issuer for more information.
            </p>

            <p class="text-theme-body font-light" v-if="type != 'non-avs'">
              The billing address you selected does not match the credit card
              you selected for payment.
            </p>

            <p class="text-theme-body font-medium" v-if="type != 'non-avs'">
              Please correct the billing address.
            </p>
          </div>
          <!-- Other Custom Slot Contents -->
          <div class="space-y-5 my-4" v-else>
            <slot />
          </div>
        </div>
        <div class="flex items-center justify-center mt-5">
          <button class="btn" @click="$emit('close')">
            Okay
          </button>
        </div>
      </div>
    </div>
  </ModalFull>
</template>
<script>
export default {
  name: "CheckoutErrorModal",
  props: {
    isVisible: Boolean,
    type: {
      type: String,
      default: "non-avs",
    },
    title: {
      type: String,
      default: "Payment Declined",
    },
    description: {
      type: String,
      default: "The credit card payment was not successful.",
    },
  },
  emits: ["close"],
};
</script>

<style lang="scss" scoped>
.notice-block {
  @apply bg-white w-full rounded -mt-16;

  @media (max-width: 640px) {
    max-height: 90vh;
    @include CssScrollbar();
    overflow-y: auto;
  }

  .btn {
    @apply inline-block w-36 text-center text-sm  leading-none px-2 py-3 rounded border-none   focus:outline-none focus:border-transparent bg-theme-secondary hover:bg-black text-white focus:ring-1 focus:ring-offset-1 focus:ring-brand-secondary;
  }
}
</style>
